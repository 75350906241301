<template>
  <div class="thank-you-page">
    <CategoryTitle :category="category" />
    <v-container class="">
      <h3>Inserisci la nuova password:</h3>
      <v-form
        ref="form"
        class="reset-password d-flex my-2"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <v-text-field
          v-model="password"
          label="Password *"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :rules="passwordRules"
          @click:append="toggleShowPassword"
          required
          outlined
          dense
        ></v-text-field>
        <v-text-field
          v-model="passwordConfirm"
          label="Conferma Password *"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :rules="passwordConfirmRules"
          @click:append="toggleShowPassword"
          required
          outlined
          dense
          clearable
        ></v-text-field>
      </v-form>
      <ResponseMessage :response="response" />
      <div class="d-flex btn-container">
        <v-btn
          @click="handleResetPassword"
          class="reset-button"
          color="primary"
          large
          depressed
          :disabled="!valid"
          >{{ $t("login.submitResetPassword") }}</v-btn
        >
      </div>
    </v-container>
  </div>
</template>
<style lang="scss">
.reset-password {
  justify-content: flex-start;
  max-width: 620px;
  .v-input__slot {
    max-width: 300px !important;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-direction: column;
    .btn-container {
      justify-content: center;
    }
  }
}
</style>
<script>
import ResponseMessage from "@/components/ResponseMessage";
import CategoryTitle from "@/components/category/CategoryTitle";

import { mapActions } from "vuex";

import UserService from "~/service/userService";
import categoryMixin from "~/mixins/category";
import {
  requiredValue,
  isEmail,
  minLength,
  minNum
} from "~/validator/validationRules";

export default {
  name: "ResetPassword",
  components: { ResponseMessage, CategoryTitle },
  mixins: [categoryMixin],
  data() {
    return {
      email: "",
      showPassword: false,
      password: null,
      passwordConfirm: null,
      valid: true,
      lazy: true,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      passwordRules: [
        requiredValue("Digitare la password"),
        minLength(8, "La password deve essere lunga almeno 8 caratteri"),
        minNum(1, "La password deve contenere almeno una cifra")
      ],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.password || "Le password non coincidono"
      ],
      errors: [],
      invalid: null,
      token: null,
      response: {},
      loginEmail: null,
      showForm: true
    };
  },
  methods: {
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    },
    ...mapActions({ loadCart: "cart/loadCart" }),
    async handleResetPassword() {
      try {
        this.loading = true;
        const response = await UserService.resetPassword(
          this.email,
          this.token,
          this.password
        );
        this.response = response;
        if (response.response.status == 0) {
          this.loadCart();
          this.$router.push({ name: "ResetPasswordCompleted" });
        }
      } catch (err) {
        if (err.response) {
          this.response = err.response.data.response;
        }
      } finally {
        this.loading = false;
        this.showForm = false;
      }
    },
    getQueryParams() {
      this.email = this.$route.query.login;
      this.token = this.$route.query.token;
    }
  },
  created() {
    this.getQueryParams();
  }
};
</script>

<style></style>
